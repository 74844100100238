<template>
  <div>
    <v-row class="d-flex justify-center mt-0">
      <v-col cols="12">
        <v-expansion-panels v-model="panel">
          <v-expansion-panel readonly>
            <!-- Boton agregar -->
            <v-btn
              style="position: absolute; top: -15px; right: 55px; z-index: 1"
              color="success"
              small
              fab
              title="Nueva Acreditación"
              @click="nuevaAcreditacion()"
            >
              <v-icon>fas fa-plus</v-icon>
            </v-btn>
            <!-- Titulo -->
            <v-expansion-panel-header class="py-0 px-4 text-h5" style="cursor: default">
              <div>
                <v-icon class="mb-1" left>fas fa-filter</v-icon>
                Filtros
              </div>
              <template v-slot:actions>
                <v-btn icon @click.stop="panel = panel === 1 ? 0 : 1">
                  <v-icon>fas fa-chevron-down</v-icon>
                </v-btn>
              </template>
            </v-expansion-panel-header>
            <!-- Filtros -->
            <v-expansion-panel-content class="pt-1">
              <!-- Cliente y Fecha -->
              <v-row :no-gutters="$vuetify.breakpoint.xs">
                <v-col cols="12" sm="2" md="2" class="py-1">
                  Cliente Código
                  <v-text-field
                    v-model="clienteCodigo"
                    :id="cliCodigo"
                    :ref="cliCodigo"
                    outlined
                    dense
                    type="number"
                    hide-details
                    :readonly="lockCliente == true"
                    @blur="getCliente()"
                    @keypress.native.enter="$refs[cliCodigo].blur()"
                  >
                    <template v-slot:append-outer>
                      <v-icon
                        class="mt-1"
                        color="info"
                        title="Buscar cliente"
                        small
                        @click="buscarCliente()"
                      >
                        fas fa-search
                      </v-icon>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="4" md="4" class="py-1">
                  Cliente Nombre
                  <v-text-field
                    v-model="clienteNombre"
                    outlined
                    dense
                    type="text"
                    hide-details
                    readonly
                    ref="cliNombre"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" :sm="big ? 6 : 3" class="py-1">
                  Fecha
                  <FechaPickerRango
                    v-model="fechaRango"
                    :limpiar.sync="limpiarFecha"
                    @changeSize="changeSize"
                  />
                </v-col>
              </v-row>
              <!-- Estado y Botones -->
              <v-row :no-gutters="$vuetify.breakpoint.xs" justify="space-between">
                <v-col cols="12" sm="2" md="2" class="py-1">
                  Estados
                  <v-autocomplete
                    v-model="selectedEstado"
                    item-text="estado_nombre"
                    :items="estados"
                    hide-details
                    outlined
                    dense
                    return-object
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="8" md="8" class="d-flex mt-4 justify-end align-end">
                  <v-btn
                    title="Limpiar filtro"
                    color="orange"
                    class="mr-3"
                    icon
                    @click="limpiar()"
                  >
                    <v-icon>fas fa-broom</v-icon>
                  </v-btn>
                  <v-btn
                    color="info"
                    tabindex="1"
                    @click="buscar()"
                  >
                    <v-icon left>fas fa-search</v-icon>
                    Buscar
                  </v-btn>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <!-- Listado -->
        <v-data-table
          class="cebra elevation-2 mt-2"
          :headers="headers"
          :items="acreditaciones"
          :loading="load"
          dense
          :search="search"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
        >
          <!-- este template se usa para aplicar formato a las columnas que tengan la propeidad formatter -->
          <template
            v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))"
            v-slot:[`item.${header.value}`]="{ value }"
          >
            {{ header.formatter(value) }}
          </template>
          <!-- formato de f pago -->
          <template v-slot:[`item.fecha_pago`]="{ value }">
            {{ value ? moment(value).format('DD/MM/YYYY') : '' }}
          </template>
          <!-- Buscador en el datatable -->
          <template v-slot:top>
            <v-row class="d-flex justify-end pa-2" no-gutters>
              <v-col cols="6" sm="3">
                <SearchDataTable
                  v-model="search"
                />
              </v-col>
            </v-row>
          </template>
          <!-- Msj que se mostrara si no existen resultados -->
          <template v-slot:no-data>
            <v-alert
              class="mx-auto mt-4"
              type="warning"
              border="left"
              dense
              text
            >
              Sin acreditaciones para mostrar.
            </v-alert>
          </template>
          <!-- Acciones -->
          <template v-slot:[`item.acciones`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn small icon color="success" @click="verAcreditacion(item)" v-on="on">
                  <v-icon small>fas fa-eye</v-icon>
                </v-btn>
              </template>
              <span>Ver</span>
            </v-tooltip>
            <!-- <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn small icon color="info" @click="editarAcreditacion(item)" v-on="on">
                  <v-icon small>fas fa-edit</v-icon>
                </v-btn>
              </template>
              <span>Editar</span>
            </v-tooltip> -->
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <BtnConfirmar 
                  :texto="`Está Anulando la acreditación por $${item.importe} para el Cliente ${item.vendedor_nombre}. ¿Confirma esta acción?`"
                  icono="fas fa-times-circle"
                  :icon_button="true"
                  color="error"
                  :small="true"
                  @action="anularAcreditacion(item)"
                  v-on="on"
                />
              </template>
              <span>Anular</span>
            </v-tooltip>
          </template>
          <!-- ultima row para el total -->
          <template slot="body.append">
            <tr class="font-weight-bold">
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th class="text-center">Total</th>
              <th class="text-center">{{ acreditaciones.length == 0 ? '$0' : format_money(acreditaciones.reduce((sum, elem) => sum + roundNumber(elem.importe, 2), 0)) }}</th>
              <th></th>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <!-- Modal Ver Acreditacion -->
    <ModalVerAcreditacion 
      :datos="dialogVerAcreditacion"
      @setearModalVerAcred="setModalVerAcred"
    />
    <!-- Modal Buscar Clientes Mayoristas -->
    <BuscarClienteMayorista 
      :datos="dialogClientes"
      @setearCliente="setCliente"
      @setearBuscarClienteM="setBuscarClienteM"
    />
  </div>
</template>

<script>
import FechaPickerRango from '../../components/util/FechaPickerRango.vue'
import BtnFiltro from '../../components/util/BtnFiltro.vue'
import SearchDataTable from '../../components/util/SearchDataTable.vue'
import ModalVerAcreditacion from '../../components/clientes/ModalVerAcreditacion.vue'
import BtnConfirmar from '../../components/util/BtnConfirmar.vue'
import BuscarClienteMayorista from '../../components/generales/clientes/BuscarClienteMayorista'
import moment from 'moment'
import { format_money, roundNumber } from '../../util/utils'
export default {
  data() {
    return {
      moment: moment,
      roundNumber: roundNumber,
      format_money: format_money,
      panel: 0,
      lockCliente: false,
      clienteCodigo: '',
      clienteNombre: '',
      fechaRango: [null, null],
      limpiarFecha: false,
      selectedEstado: {},
      estados: [],
      headers: [
        { text: 'Origen', align: 'center', value: 'entidad_nombre' },
        { text: 'F. Pago', align: 'center', value: 'fecha_pago' },
        { text: 'Lote', align: 'center', value: 'lote' },
        { text: 'Cupón', align: 'center', value: 'cupon' },
        { text: 'Cod. Aut.', align: 'center', value: 'codigo_autorizacion' },
        { text: 'Tarjeta', align: 'center', value: 'tarjeta' },
        { text: 'Tar. Num.', align: 'center', value: 'tarjeta_num' },
        { text: 'Vend. Cod.', align: 'center', value: 'vendedor_codigo' },
        { text: 'Vend. Nom.', align: 'left', value: 'vendedor_nombre' },
        { text: 'Usu. Grab.', align: 'center', value: 'usuario_grabacion' },
        { text: 'Importe', align: 'center', value: 'importe', formatter: format_money },
        { text: 'Acciones', align: 'center', value: 'acciones' },
      ],
      acreditaciones: [],
      big: false,
      load: false,
      search: '',
      tienePermiso: 0,
      ejecutivoCodigo: 0,
      cliCodigo: 'cliCodigo',
      dialogVerAcreditacion: {
        activo: false,
        acreditacion: {},
        nuevo: 0,
        editar: 0,
        tiene_permiso: 0,
        ejecutivo_codigo: 0,
        origenes: [],
        estados: [],
        tarjetas: []
      },
      sortBy: 'fecha_pago',
      sortDesc: true,
      dialogClientes: {
        ruta: 'clientes/getClienteMayorista',
        activo: false,
        ejecutivo_codigo: ''
      }
    }
  },
  components: {
    FechaPickerRango,
    BtnFiltro,
    SearchDataTable,
    ModalVerAcreditacion,
    BtnConfirmar,
    BuscarClienteMayorista
  },
  created () {
    this.initForm()
  },
  methods: {
    async initForm(){
      this.$store.state.loading = true
      let initPeticion = await this.$store.dispatch('acreditaciones/initFormABMAcreditaciones')
      this.$store.state.loading = false
      if (initPeticion.resultado == 1){
        this.estados = initPeticion.estados
        this.tienePermiso = initPeticion.permiso
        this.dialogVerAcreditacion.estados = initPeticion.estados
        this.dialogVerAcreditacion.origenes = initPeticion.origenes
        this.dialogVerAcreditacion.tarjetas = initPeticion.tarjetas
        if (initPeticion.ejecutivo.length > 0) this.ejecutivoCodigo = initPeticion.ejecutivo[0].ejecutivo_codigo
      }else{
        this.$store.dispatch('show_snackbar', {
          text: initPeticion.msj,
          color: 'error',
        })
      }
    },
    async buscar(){
      this.acreditaciones = []
      // codigo del cliente
      let cliente = 0
      if (this.clienteCodigo != null && this.clienteCodigo != undefined && this.clienteCodigo.toString().length > 0) cliente = this.clienteCodigo
      // fecha hasta
      let fechaHastaBus = ''
      if (this.fechaRango[1] && null || this.fechaRango[1] != undefined && this.fechaRango[1].length > 0){
        fechaHastaBus = moment(this.fechaRango[1]).format('DD/MM/YYYY')
      }
      // estado
      let estado = 0
      if (Object.keys(this.selectedEstado).length > 0) estado = this.selectedEstado.estado_id
      // peticion
      this.load = true
      let detallesPeticion = await this.$store.dispatch('acreditaciones/getDetsAcreditaciones', {
        cliente_codigo: cliente,
        ejecutivo_codigo: this.ejecutivoCodigo,
        estado: estado,
        fecha_desde: moment(this.fechaRango[0]).format('DD/MM/YYYY'),
        fecha_hasta: fechaHastaBus
      })
      this.load = false
      if (detallesPeticion.resultado == 1){
        this.acreditaciones = detallesPeticion.acreditaciones
      }else{
        this.$store.dispatch('show_snackbar', {
          text: detallesPeticion.msj,
          color: 'error',
        })
      }
    },
    async getCliente(){
      if (this.clienteCodigo != null && this.clienteCodigo != undefined && this.clienteCodigo.toString().length > 0){
        this.$store.state.loading = true
        let clientePeticion = await this.$store.dispatch('clientes/getClienteMayorista', {
          codigo: this.clienteCodigo,
          ejecutivo_codigo: this.ejecutivoCodigo,
          nombre: '',
          nroDocumento: 0
        })
        this.$store.state.loading = false
        if (clientePeticion.resultado == 1){
          if (clientePeticion.clientes.length > 0){
            this.clienteNombre = clientePeticion.clientes[0].vendedor_nombre
            this.buscar()
          }else{
            this.$store.dispatch('show_snackbar', {
              text: 'No se encontraron Acreditaciones para el Cliente ingresado o bien no está habilitado a ver sus Acreditaciones.',
              color: 'info',
            })
            this.clienteCodigo = ''
            this.clienteNombre = ''
          }
        }else{
          this.$store.dispatch('show_snackbar', {
            text: clientePeticion.msj,
            color: 'error',
          })
        }
        // muevo el foco al nombre del cliente
        this.$refs.cliNombre.focus()
      }
    },
    limpiar(){
      this.limpiarFecha = true
      this.clienteCodigo = ''
      this.clienteNombre = ''
      this.selectedEstado = {}
      this.search = ''
    },
    async verAcreditacion(item){
      await this.get_cupon(item)
      this.dialogVerAcreditacion.acreditacion = item
      this.dialogVerAcreditacion.nuevo = 0
      this.dialogVerAcreditacion.tiene_permiso = this.tienePermiso
      this.dialogVerAcreditacion.ejecutivo_codigo = this.ejecutivoCodigo
      this.dialogVerAcreditacion.activo = true
    },
    setModalVerAcred(value){
      this.dialogVerAcreditacion.activo = value
      if (this.dialogVerAcreditacion.activo == false){
        this.dialogVerAcreditacion.acreditacion = {}
        this.dialogVerAcreditacion.nuevo = 0
        this.dialogVerAcreditacion.editar = 0
        this.dialogVerAcreditacion.tiene_permiso = 0
        this.dialogVerAcreditacion.ejecutivo_codigo = 0
      }
    },
    nuevaAcreditacion(){
      this.dialogVerAcreditacion.acreditacion = {}
      this.dialogVerAcreditacion.nuevo = 1
      this.dialogVerAcreditacion.editar = 0
      this.dialogVerAcreditacion.tiene_permiso = this.tienePermiso
      this.dialogVerAcreditacion.ejecutivo_codigo = this.ejecutivoCodigo
      this.dialogVerAcreditacion.activo = true
    },
    async editarAcreditacion(item){
      if (item.circuito == 2){
        this.$store.state.loading = true
        let puedeEditarPeticion = await this.$store.dispatch('acreditaciones/puedeEditarAcreditacion', {numero: item.numero})
        this.$store.state.loading = false
        if (puedeEditarPeticion.resultado == 1){
          await this.get_cupon(item)
          this.dialogVerAcreditacion.acreditacion = item
          this.dialogVerAcreditacion.nuevo = 0
          this.dialogVerAcreditacion.editar = 1
          this.dialogVerAcreditacion.tiene_permiso = this.tienePermiso
          this.dialogVerAcreditacion.ejecutivo_codigo = this.ejecutivoCodigo
          this.dialogVerAcreditacion.activo = true
        }else{
          this.$store.dispatch('show_snackbar', {
            text: puedeEditarPeticion.msj,
            color: 'error',
          })
        }
      }else{
        this.$store.dispatch('show_snackbar', {
          text: 'No se pueden editar este tipo de Acreditaciones. Circuito: ' + item.circuito,
          color: 'info',
        })
      }
    },
    async anularAcreditacion(item){
      if (item.circuito == 2){
        this.$store.state.loading = true
        let anularPeticion = await this.$store.dispatch('acreditaciones/anularAcreditacion', {numero: item.numero})
        this.$store.state.loading = false
        if (anularPeticion.resultado == 1){
          this.$store.dispatch('show_snackbar', {
            text: 'Acreditación anulada con éxito.',
            color: 'success',
          })
          this.buscar()
        }else{
          this.$store.dispatch('show_snackbar', {
            text: anularPeticion.msj,
            color: 'error',
          })
        }
      }else{
        this.$store.dispatch('show_snackbar', {
          text: 'No se pueden anular este tipo de Acreditaciones. Circuito: ' + item.circuito,
          color: 'info',
        })
      }
    },
    buscarCliente(){
      // asigno el ejecutivo
      this.dialogClientes.ejecutivo_codigo = this.ejecutivoCodigo
      // abro el modal
      this.dialogClientes.activo = true
    },
    setBuscarClienteM(value){
      this.dialogClientes.activo = value
      if (this.dialogClientes.activo == false) this.dialogClientes.ejecutivo_codigo = ''
    },
    setCliente(item){
      this.clienteCodigo = item.vendedor_codigo
      this.clienteNombre = item.vendedor_nombre
      this.buscar()
    },
    async get_cupon (item) {
      if (item.archivo_cupon) {
        this.$store.state.loading = true
        await this.$store.dispatch('get_base64', '/jarvis_2.0/indirecta_acre/' + item.archivo_cupon)
          .then((res) => {
            item.archivo = res.base
          })
          .catch(error => {
            this.$store.dispatch('show_snackbar', {
              text: error.message,
              color: 'error'
            })
          })
        this.$store.state.loading = false
      }
    },
    changeSize (custom) {
      this.big = custom
    }
  },
  watch: {
    acreditaciones: function(){
      if (this.acreditaciones.length > 0){
        for (let id in this.acreditaciones){
          if (this.acreditaciones[id].fecha_anulacion != null && this.acreditaciones[id].fecha_anulacion != undefined && this.acreditaciones[id].fecha_anulacion.toString().length > 0) this.acreditaciones[id].fecha_anulacion = moment(this.acreditaciones[id].fecha_anulacion).format('DD/MM/YYYY')
          if (this.acreditaciones[id].fecha_grabacion != null && this.acreditaciones[id].fecha_grabacion != undefined && this.acreditaciones[id].fecha_grabacion.toString().length > 0) this.acreditaciones[id].fecha_grabacion = moment(this.acreditaciones[id].fecha_grabacion).format('DD/MM/YYYY')
          //if (this.acreditaciones[id].fecha_pago != null && this.acreditaciones[id].fecha_pago != undefined && this.acreditaciones[id].fecha_pago.toString().length > 0) this.acreditaciones[id].fecha_pago = moment(this.acreditaciones[id].fecha_pago).format('DD/MM/YYYY')
        }
      }
    }
  },
}
</script>

<style>

</style>