<template>
  <div>
    <v-row class="d-flex justify-center mt-0">
      <v-col cols="12">
        <v-expansion-panels v-model="panel">
          <v-expansion-panel readonly>
            <!-- Filtros Título -->
            <v-expansion-panel-header class="py-0 px-4 text-h5" style="cursor: default">
              <div>
                <v-icon class="mb-1" left>fas fa-filter</v-icon>
                Filtro
              </div>
              <template v-slot:actions>
                <v-btn icon @click.stop="panel = panel === 1 ? 0 : 1">
                  <v-icon>fas fa-chevron-down</v-icon>
                </v-btn>
              </template>
            </v-expansion-panel-header>
            <!-- Filtros -->
            <v-expansion-panel-content class="pt-2">
              <!-- Cliente, Entidad y Estados -->
              <v-row :no-gutters="$vuetify.breakpoint.xs">
                <v-col cols="6" sm="2" md="2" class="py-1">
                  Cliente Código
                  <v-text-field
                    v-model="clienteCodigo"
                    :id="cliCodigo"
                    :ref="cliCodigo"
                    outlined
                    dense
                    type="number"
                    hide-details
                    :readonly="lockCliente == true"
                    :filled="lockCliente == true"
                    @blur="getCliente()"
                    @keypress.native.enter="$refs[cliCodigo].blur()"
                  ></v-text-field>  
                </v-col>
                <v-col cols="12" sm="4" md="4" class="py-1">
                  Cliente Nombre
                  <v-text-field
                    v-model="clienteNombre"
                    outlined
                    dense
                    type="text"
                    hide-details
                    readonly
                    ref="cliNombre"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="3" md="3" class="py-1">
                  Origen
                  <v-autocomplete
                    v-model="selectedEntidad"
                    item-text="entidad_nombre"
                    :items="entidades"
                    hide-details
                    outlined
                    dense
                    return-object
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="3" md="3" class="py-1">
                  Estados
                  <v-autocomplete
                    v-model="selectedEstado"
                    item-text="estado_nombre"
                    :items="estados"
                    hide-details
                    outlined
                    dense
                    return-object
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <!-- Botones -->
              <v-row :no-gutters="$vuetify.breakpoint.xs" class="justify-end">
                <v-col :cols="big ? 12 : 6" :sm="big ? 8 : 3" :md="big ? 5 : 2">
                  Fecha
                  <FechaPickerRango
                    v-model="fechas"
                    :limpiar.sync="clear"
                    :rango_def="5"
                    @changeSize="changeSize"
                  />
                </v-col>
                <v-col cols="6" sm="4" md="3">
                  <v-checkbox
                    v-model="conSaldo"
                    label="Con Saldos"
                    :class="`mt-6 ml-${big && $vuetify.breakpoint.xs ? 0 : 6}`"
                    :true-value="1"
                    :false-value="0"
                    hide-details
                  ></v-checkbox>
                </v-col>
                <v-col cols="6" :sm="big ? 6 : 5" :md="big ? 4 : 7" class="d-flex justify-end align-end mt-4 mt-sm-2">
                  <v-btn
                    title="Limpiar filtro"
                    color="orange"
                    class="mr-3"
                    icon
                    @click="limpiar"
                  >
                    <v-icon>fas fa-broom</v-icon>
                  </v-btn>
                  <v-btn
                    color="info"
                    tabindex="1"
                    @click="buscar()"
                  >
                    <v-icon left>fas fa-search</v-icon>
                    Buscar
                  </v-btn>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <!-- Listado -->
        <v-data-table
          class="cebra elevation-2 mt-2"
          item-key="detalle_numero"
          :headers="headers"
          :items="acreditaciones"
          :item-class="itemRowBackground"
          :loading="load"
          :search="search"
          :expanded.sync="expanded"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          single-expand
          show-expand
          dense
        >
          <template v-slot:[`item.importe_acreditado`]="{ item }">
            {{ formatMoney(item.importe - item.importe_intereses) }}
          </template>
          <!-- formato de f pago -->
          <template v-slot:[`item.f_pago`]="{ value }">
            {{ value ? moment(value).format('DD/MM/YYYY') : '' }}
          </template>
          <!-- este template se usa para aplicar formato a las columnas que tengan la propeidad formatter -->
          <template
            v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))"
            v-slot:[`item.${header.value}`]="{ value }"
          >
            {{ header.formatter(value) }}
          </template>
          <template v-slot:top>
            <v-row class="d-flex justify-end pa-2" no-gutters>
              <!-- Boton de saldo -->
              <v-col cols="6" sm="9">
                <v-btn
                  v-if="modalSaldosFavor.cliente_codigo != null && modalSaldosFavor.cliente_codigo != undefined && modalSaldosFavor.cliente_codigo.toString().length > 0"
                  color="success"
                  @click="verSaldosFavor()"
                >
                  Saldos
                </v-btn>
              </v-col>
              <!-- Buscador en el datatable -->
              <v-col cols="6" sm="3">
                <SearchDataTable
                  v-model="search"
                />
              </v-col>
            </v-row>
          </template>
          <!-- Msj que se mostrara si no existen resultados -->
          <template v-slot:no-data>
            <v-alert
              class="mx-auto mt-4"
              type="warning"
              border="left"
              dense
              text
            >
              Sin acreditaciones para mostrar.
            </v-alert>
          </template>
          <!-- Expansion, Tabla para las Imputaciones por Detalle -->
          <template v-slot:expanded-item="{ headers }">
            <td :colspan="headers.length">
              <v-row>
                <v-col cols="12">
                  <v-card
                    flat
                  >
                    <v-card-text>
                      <v-data-table
                      class="cebra elevation-2 mt-2"
                      :headers="headerss"
                      :items="imputaciones"
                      :loading="loadd"
                      dense
                      :search="searchh"
                      hide-default-footer
                      >
                        <!-- este template se usa para aplicar formato a las columnas que tengan la propeidad formatter -->
                        <template
                          v-for="header in headerss.filter((header) => header.hasOwnProperty('formatter'))"
                          v-slot:[`item.${header.value}`]="{ value }"
                        >
                          {{ header.formatter(value) }}
                        </template>
                        <!-- Buscador en el datatable -->
                        <template v-slot:top>
                          <v-row class="d-flex justify-end pa-2" no-gutters>
                            <v-col cols="12">
                              <strong>Detalle Imputaciones</strong>
                            </v-col>
                          </v-row>
                        </template>
                        <!-- Msj que se mostrara si no existen resultados -->
                        <template v-slot:no-data>
                          <v-alert
                            class="mx-auto mt-4"
                            type="warning"
                            border="left"
                            dense
                            text
                          >
                            Sin imputaciones para mostrar.
                          </v-alert>
                        </template>
                      </v-data-table>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </td>
          </template>
          <!-- ultima row para el total -->
          <template slot="body.append">
            <tr class="font-weight-bold">
              <th></th>
              <th>Total</th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th class="text-end">{{ formatMoney(totalAcred) }}</th>
              <th class="text-end">{{ formatMoney(saldoTotal) }}</th>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <!-- Modal Saldos a Favor -->
    <SaldosAFavor 
      :datos="modalSaldosFavor"
      @setearModalSaldos="setModalSaldos"
    />
  </div>
</template>

<script>
import BtnFiltro from '../../components/util/BtnFiltro.vue'
import SearchDataTable from '../../components/util/SearchDataTable.vue'
import FechaPickerRango from '../../components/util/FechaPickerRango.vue'
import moment from 'moment'
import { format_money, convertDecimals, format_date } from '../../util/utils'
import SaldosAFavor from '../../components/clientes/SaldosAFavor.vue'
export default {
  data() {
    return {
      moment: moment,
      formatMoney: format_money,
      panel: 0,
      conSaldo: 0,
      clienteCodigo: '',
      clienteNombre: '',
      entidades: [],
      selectedEntidad: {},
      selectedEstado: {},
      estados: [],
      big: false,
      load: false,
      clear: false,
      fechas: [ null, null ],
      headers: [
        { text: 'Origen', align: 'center', value: 'entidad' },
        { text: 'F. Pago', align: 'center', value: 'f_pago' },
        { text: 'Lote', align: 'center', value: 'lote' },
        { text: 'Cupón', align: 'center', value: 'cupon' },
        { text: 'Cód. Aut.', align: 'center', value: 'codigo_autorizacion' },
        { text: 'Tarjeta', align: 'center', value: 'tarjeta' },
        { text: 'Tar. Num.', align: 'center', value: 'tarjeta_num' },
        { text: 'Importe', align: 'end', value: 'importe', formatter: format_money },
        { text: 'Importe Acred.', align: 'end', value: 'importe_acreditado' },
        { text: 'Saldo', align: 'end', value: 'saldo', formatter: format_money }
      ],
      headerss: [
        { text: 'Cobranza', align: 'center', value: 'numero' },
        { text: 'Fecha', align: 'center', value: 'fecha' },
        { text: 'Nro. Comp.', align: 'center', value: 'comp' },
        { text: 'Imputación', align: 'center', value: 'imputacion' },
        { text: 'Referencia', align: 'center', value: 'referencia' },
        { text: 'Imp. Imputado', align: 'end', value: 'importe', formatter: format_money }
      ],
      acreditaciones: [],
      search: '',
      lockCliente: false,
      tienePermiso: 0,
      ejecutivoCodigo: 0,
      clienteActual: {},
      totalAcred: 0,
      saldoTotal: 0,
      expanded: [],
      imputaciones: [],
      loadd: false,
      searchh: '',
      buscandoImputacion: 0,
      modalSaldosFavor: {
        activo: false,
        cliente_codigo: '',
        cliente_nombre: ''
      },
      sortBy: 'f_pago',
      sortDesc: true,
      cliCodigo: 'cliCodigo'
    }
  },
  components: {
    BtnFiltro,
    SearchDataTable,
    FechaPickerRango,
    SaldosAFavor
  },
  created () {
    this.initForm()
  },
  methods: {
    async initForm(){
      this.$store.state.loading = true
      let initPeticion = await this.$store.dispatch('acreditaciones/initForm')
      this.$store.state.loading = false
      if (initPeticion.resultado == 1){
        this.entidades = initPeticion.entidades
        this.estados = initPeticion.estados
        // tiene permiso de ver todos los clientes?
        if (initPeticion.permiso == 1){
          this.tienePermiso = initPeticion.permiso
          return
        }
        // si no tiene permiso, asigno el resto de la info, vino con datos de cliente?
        if (initPeticion.cliente.length > 0){
          // vino con datos de cliente por lo tanto lleno el codigo de cliente y no permito cambiar
          this.lockCliente = true
          this.clienteCodigo = initPeticion.cliente[0].cliente_codigo
          this.clienteNombre = initPeticion.cliente[0].cliente_nombre
          this.ejecutivoCodigo = initPeticion.cliente[0].ejecutivo_codigo
          this.modalSaldosFavor.cliente_codigo = initPeticion.cliente[0].cliente_codigo
          this.modalSaldosFavor.cliente_nombre = initPeticion.cliente[0].cliente_nombre
          return
        }
        // es un ejecutivo?
        if (initPeticion.ejecutivo.length > 0){
          // guardo el código del ejecutivo
          this.ejecutivoCodigo = initPeticion.ejecutivo[0].ejecutivo_codigo
        }
      }else{
        this.$store.dispatch('show_snackbar', {
          text: initPeticion.msj,
          color: 'error',
        })
      }
    },
    limpiar(){
      if (this.lockCliente == false){
        this.clienteCodigo = ''
        this.clienteNombre = ''
      }
      this.selectedEntidad = {}
      this.selectedEstado = {}
      this.clear = true
      this.fechas = [ null, null ]
    },
    async getCliente(){
      if (this.clienteCodigo != null && this.clienteCodigo != undefined && this.clienteCodigo.toString().length > 0 && this.lockCliente == false){
        this.modalSaldosFavor.cliente_codigo = ''
        this.modalSaldosFavor.cliente_nombre = ''
        this.clienteActual = {}
        this.$store.state.loading = true
        let clientePeticion = await this.$store.dispatch('clientes/getClienteMayorista', {
          codigo: this.clienteCodigo,
          ejecutivo_codigo: this.ejecutivoCodigo,
          nombre: '',
          nroDocumento: 0
        })
        this.$store.state.loading = false
        if (clientePeticion.resultado == 1){
          if (clientePeticion.clientes.length > 0){
            this.clienteActual = clientePeticion.clientes[0]
            this.clienteNombre = clientePeticion.clientes[0].vendedor_nombre
            this.modalSaldosFavor.cliente_codigo = this.clienteCodigo
            this.modalSaldosFavor.cliente_nombre = clientePeticion.clientes[0].vendedor_nombre
            this.buscar()
          }else{
            this.$store.dispatch('show_snackbar', {
              text: 'No se encontraron Saldos para el Cliente ingresado o bien no está habilitado a ver sus Saldos.',
              color: 'info',
            })
            this.clienteCodigo = ''
            this.clienteNombre = ''
          }
        }else{
          this.$store.dispatch('show_snackbar', {
            text: clientePeticion.msj,
            color: 'error',
          })
        }
        // muevo el foco al nombre del cliente
        this.$refs.cliNombre.focus()
      }
    },
    async buscar(){
      if (this.clienteCodigo == null || this.clienteCodigo == undefined || this.clienteCodigo.toString().length == 0){
        this.$store.dispatch('show_snackbar', {
          text: 'Debe ingresar el código del Cliente para realizar una búsqueda.',
          color: 'warning',
        })
        return
      }
      this.acreditaciones = []
      this.saldoTotal = 0
      this.totalAcred = 0
      this.imputaciones = []
      this.expanded = []
      // filtra entidad?
      let entidad = 0
      if (Object.keys(this.selectedEntidad).length > 0) entidad = this.selectedEntidad.entidad_id
      // estado?
      let string_estado = ''
      if (Object.keys(this.selectedEstado).length > 0) string_estado = this.selectedEstado.condicion_select
      this.load = true
      let detallesPeticion = await this.$store.dispatch('acreditaciones/getAcreditaciones', {
        cliente_codigo: this.clienteCodigo,
        entidad_codigo: entidad,
        estado_condicion: string_estado,
        con_saldo: this.conSaldo,
        fecha_desde: format_date(this.fechas[0]),
        fecha_hasta: format_date(this.fechas[1])
      })
      this.load = false
      if (detallesPeticion.resultado == 1){
        this.acreditaciones = detallesPeticion.acreditaciones
      }else{
        this.$store.dispatch('show_snackbar', {
          text: detallesPeticion.msj,
          color: 'error',
        })
      }
    },
    async getImputaciones(){
      let item = this.expanded[0]
      this.imputaciones = []
      this.$store.state.loading = true
      let imputacionesPeticion = await this.$store.dispatch('acreditaciones/getImputacionesCliente', {
        cliente_codigo: item.vendedor_codigo,
        detalle_numero: item.detalle_numero
      })
      this.$store.state.loading = false
      this.buscandoImputacion = 0
      if (imputacionesPeticion.resultado == 1){
        this.imputaciones = imputacionesPeticion.imputaciones
      }else{
        this.$store.dispatch('show_snackbar', {
          text: imputacionesPeticion.msj,
          color: 'error',
        })
        this.expanded = []
      }
    },
    verSaldosFavor(){
      // abro el modal
      this.modalSaldosFavor.activo = true
    },
    setModalSaldos(value){
      this.modalSaldosFavor.activo = value
    },
    itemRowBackground (item) {
      return parseFloat(item.saldo) > 0 ? 'background-color: rowsuc' : ''
    },
    changeSize (custom) {
      this.big = custom
    }
  },
  watch: {
    acreditaciones: function(){
      if (this.acreditaciones.length > 0){
        for (let id in this.acreditaciones){
          // fechas
          if (this.acreditaciones[id].fecha_grabacion != null && this.acreditaciones[id].fecha_grabacion != undefined && this.acreditaciones[id].fecha_grabacion.toString().length > 0) this.acreditaciones[id].fecha_grabacion = moment(this.acreditaciones[id].fecha_grabacion).format('DD/MM/YYYY')
          if (this.acreditaciones[id].fecha_pago != null && this.acreditaciones[id].fecha_pago != undefined && this.acreditaciones[id].fecha_pago.toString().length > 0) this.acreditaciones[id].fecha_pago = moment(this.acreditaciones[id].fecha_pago).format('DD/MM/YYYY')
          //if (this.acreditaciones[id].f_pago != null && this.acreditaciones[id].f_pago != undefined && this.acreditaciones[id].f_pago.toString().length > 0) this.acreditaciones[id].f_pago = moment(this.acreditaciones[id].f_pago).format('DD/MM/YYYY')
          // acumulo el saldo
          this.saldoTotal = this.saldoTotal + convertDecimals(this.acreditaciones[id].saldo)
          this.totalAcred += parseFloat(this.acreditaciones[id].importe) - parseFloat(this.acreditaciones[id].importe_intereses)
        }
      }
    },
    expanded: function(){
      if (this.expanded.length > 0 && this.buscandoImputacion == 0){
        this.buscandoImputacion = 1
        this.getImputaciones(this.expanded[0])
      }
    },
    imputaciones: function(){
      if (this.imputaciones.length > 0){
        for (let id in this.imputaciones){
          this.imputaciones[id].fecha = moment(this.imputaciones[id].fecha).format('DD/MM/YYYY')
        }
      }
    }
  },
}
</script>

<style>

</style>